html,
body {
  font-size: 14px;
  width: 100%;
}

#tooltip {
  position: fixed;
  visibility: hidden;
}

#tooltip .tooltipContainer {
  z-index: 3;
  position: absolute;
  width: max-content;
}
