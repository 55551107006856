#dateTimeRangePicker {
  padding: 4px;
}

#dateTimeRangePicker * {
  font-size: 14px !important;
}

@media (50px <= width <= 768px) {
  #dateTimeRangePicker > div {
    display: block !important;
  }
}

#dateTimeRangePicker .cancelButton {

}

#dateTimeRangePicker .applyButton {
  background-color: #00D88A !important;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ECEFF3;
}

#dateTimeRangePicker .calendarCell {
  outline: none !important;
}

#dateTimeRangePicker .inputDate {
  height: 30px;
  width: calc(100% - 16px);
  padding: 0 8px;
  margin: 0 8px;
  border: 1px solid #ECEFF3;
  border-radius: 4px;
}

#dateTimeRangePicker .inputDate:focus {
  border: 1px solid #004BF6;
}

#dateTimeRangePicker #Hour_start {
  border: 1px solid #ECEFF3;
  border-radius: 4px;
  padding: 4px;
}

#dateTimeRangePicker #Hour_start:focus {
  border: 1px solid #004BF6;
}

#dateTimeRangePicker #Minutes_start {
  border: 1px solid #ECEFF3;
  border-radius: 4px;
  padding: 4px;
}

#dateTimeRangePicker #Minutes_start:focus {
  border: 1px solid #004BF6;
}

#dateTimeRangePicker #Hour_end {
  border: 1px solid #ECEFF3;
  border-radius: 4px;
  padding: 4px;
}

#dateTimeRangePicker #Hour_end:focus {
  border: 1px solid #004BF6;
}

#dateTimeRangePicker #Minutes_end {
  border: 1px solid #ECEFF3;
  border-radius: 4px;
  padding: 4px;
}

#dateTimeRangePicker #Minutes_end:focus {
  border: 1px solid #004BF6;
  outline: none !important;
}

#dateTimeRangePicker #MonthSelector_start {
  border: 1px solid #ECEFF3;
  border-radius: 4px;
  outline: none !important;
  padding: 4px;
}

#dateTimeRangePicker #MonthSelector_start:focus {
  border: 1px solid #004BF6;
  outline: none !important;
}

#dateTimeRangePicker #YearSelector_start {
  border: 1px solid #ECEFF3;
  border-radius: 4px;
  outline: none !important;
  padding: 4px;
}

#dateTimeRangePicker #YearSelector_start:focus {
  border: 1px solid #004BF6;
  outline: none !important;
}

#dateTimeRangePicker #MonthSelector_end {
  border: 1px solid #ECEFF3;
  border-radius: 4px;
  outline: none !important;
  padding: 4px;
}

#dateTimeRangePicker #MonthSelector_end:focus {
  border: 1px solid #004BF6;
  outline: none !important;
}

#dateTimeRangePicker #YearSelector_end {
  border: 1px solid #ECEFF3;
  border-radius: 4px;
  outline: none !important;
  padding: 4px;
}

#dateTimeRangePicker #YearSelector_end:focus {
  border: 1px solid #004BF6;
  outline: none !important;
}

#dateTimeRangePicker .rangecontainer > :last-child > .rangebuttontextstyle {
  font-size: 0 !important;
}

#dateTimeRangePicker .rangecontainer > :last-child > .rangebuttontextstyle::after {
  content: "Произвольно";
  font-size: 14px !important;
}

#dateTimeRangePicker #buttonContainer {
  float: unset !important;
  position: absolute !important;
  justify-content: flex-end;
  @media (50px <= width <= 768px) {
    position: static !important;
  }
}

#dateTimeRangePicker .fromDateTimeContainer {
  flex-shrink: 0;
}


